import type { SVGProps } from "react"
import spriteHref from "./sprite.svg"
import type { IconName } from "./types"

import { cn } from "@sagaware/utils"

export enum IconSize {
	xs = "12",
	sm = "16",
	md = "24",
	lg = "32",
	xl = "40",
}

export type IconSizes = keyof typeof IconSize

export interface IconProps extends SVGProps<SVGSVGElement> {
	name: IconName
	testId?: string
	className?: string
	size?: IconSizes
	fillColor?: string
	stroke?: string
	title?: string
}

/**
 * Icon component wrapper for SVG icons.
 * @returns SVG icon as a react component
 */
export const Icon = ({
	name,
	testId,
	className,
	size = "md",
	fillColor = "currentColor",
	stroke = "currentColor",
	...props
}: IconProps) => {
	const iconSize = IconSize[size]
	const iconClasses = cn("inline-block flex-shrink-0", className)
	return (
		<svg
			className={iconClasses}
			fill={fillColor}
			stroke={stroke}
			width={iconSize}
			height={iconSize}
			data-testid={testId}
			data-name={name}
			{...props}
		>
			<title>{name}</title>
			<use href={`${spriteHref}#${name}`} />
		</svg>
	)
}
export type { IconName }
